import { doc, getDoc, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { isEmailInAdminList } from "../libs/admin";

export default function AdminPage(props) {
  const isAuthorizedUser = isEmailInAdminList(props.user.email);
  const [username, setUsername] = useState("bobanim");
  const [listingStartDate, setListingStartDate] = useState("2022-10-10");
  const [listingEndDate, setListingEndDate] = useState("2022-10-11");
  const [isProd, setIsProd] = useState(false);
  console.log(isProd);
  const [connectEmulator, setConnectEmulator] = useState(false);

  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [updateEmailResult, setUpdateEmailResult] = useState("");
  const [disableUpdateEmail, setDisableUpdateEmail] = useState(false);

  const [recipients, setRecipients] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [sendPushResult, setSendPushResult] = useState(null);
  const [disableSendPush, setDisableSendPush] = useState(false);

  const [profileEmail, setProfileEmail] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [profileError, setProfileError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const functions = getFunctions();
  if (connectEmulator) {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
  const sendListingEmails = httpsCallable(functions, "sendListingEmails");
  const updateUserEmail = httpsCallable(functions, "updateUserEmailV2");
  const sendMassPushNotification = httpsCallable(
    functions,
    "sendMassPushNotification"
  );
  const updateUserDisplayName = httpsCallable(
    functions,
    "updateUserDisplayName"
  );

  const getUserByEmail = httpsCallable(functions, "getUserByEmail");

  const onSendListingEmails = (e) => {
    e.preventDefault();
    if (username && listingStartDate && listingEndDate) {
      sendListingEmails({
        username: username,
        listingStartDate: listingStartDate,
        listingEndDate: listingEndDate,
        isProd: isProd,
      });
    } else {
      console.log("Input is missing");
    }
  };

  const onUpdateUserEmail = (e) => {
    e.preventDefault();
    setDisableUpdateEmail(true);
    if (currentEmail && newEmail) {
      updateUserEmail({
        currentEmail: currentEmail,
        newEmail: newEmail,
      })
        .then(
          (res) => {
            setUpdateEmailResult(res.data);
          },
          (e) => {
            setUpdateEmailResult(e.message);
          }
        )
        .finally(() => {
          setDisableUpdateEmail(false);
        });
    } else {
      setUpdateEmailResult("Missing input");
      setDisableUpdateEmail(false);
    }
  };

  const onSendPushNotification = async (e) => {
    e.preventDefault();
    setDisableSendPush(true);
    setSendPushResult(null);
    let data = null;
    if (recipients === "EVERYONE@woofyclub.com") {
      data = {
        sendToAll: true,
        recipientIds: [],
        title,
        body,
      };
    } else {
      data = {
        sendToAll: false,
        recipientIds: recipients.split(","),
        title,
        body,
      };
    }
    const res = await sendMassPushNotification(data);
    if (res.data?.error) {
      setSendPushResult(res.data?.error.message);
    } else {
      setSendPushResult(res.data?.result.message);
    }
    setDisableSendPush(false);
  };

  const onUpdateUserDisplayName = (e) => {
    e.preventDefault();
    updateUserDisplayName()
      .then(
        (res) => {
          //    setUpdateEmailResult(res.data);
        },
        (e) => {
          //    setUpdateEmailResult(e.message);
        }
      )
      .finally(() => {});
  };

  const fetchUserProfile = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setUserProfile(null);
    setProfileError("");

    if (!profileEmail) {
      setProfileError("Please enter an email address");
      setIsLoading(false);
      return;
    }

    try {
      // Call the Cloud Function to get the user's UID
      // Use the emulator if in development environment
      const getUserByEmail = httpsCallable(functions, "getProfileByEmail");
      const result = await getUserByEmail({ email: profileEmail });
      const uid = result.data.uid;

      // Then, use the UID to fetch the user profile from Firestore
      const db = getFirestore();
      const userRef = doc(db, "users", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserProfile(userSnap.data());
      } else {
        setProfileError("No user profile found for this email");
      }
    } catch (error) {
      setProfileError("Error fetching user profile: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("In AdminPage.jsx, isAuthorizedUser: ", isAuthorizedUser);

  return isAuthorizedUser ? (
    <>
      <Container>
        <Row className="justify-content-md-center">
          <Col md="4">
            <h2>Fetch User Profile</h2>
            <Form onSubmit={fetchUserProfile}>
              <Form.Group className="mb-3">
                <Form.Label>User Email</Form.Label>
                <Form.Control
                  type="email"
                  value={profileEmail}
                  onChange={(e) => setProfileEmail(e.target.value)}
                  placeholder="Enter email"
                  required
                />
              </Form.Group>
              <Button type="submit" className="mb-3" disabled={isLoading}>
                {isLoading ? "Loading..." : "Fetch Profile"}
              </Button>
            </Form>
            {profileError && <p className="text-danger">{profileError}</p>}
            {userProfile && (
              <div>
                <p>
                  <a
                    href={`https://internal.woofyclub.com/profile/${userProfile.username}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://internal.woofyclub.com/profile/
                    {userProfile.username}
                  </a>
                </p>
              </div>
            )}
          </Col>
          <hr />
        </Row>

        <Row className="justify-content-md-center">
          <Col md="4">
            <h2>Send REAL Listing Emails</h2>
            <Form>
              <label htmlFor="" className="form-label">
                Username
              </label>
              <input
                type="text"
                className="form-control"
                id="username-input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label htmlFor="" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="start-date-input"
                value={listingStartDate}
                onChange={(e) => setListingStartDate(e.target.value)}
                required
              />
              <label htmlFor="" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="end-date-input"
                value={listingEndDate}
                onChange={(e) => setListingEndDate(e.target.value)}
                required
              />
              <label htmlFor="" className="form-label">
                [BE CAREFUL] Send email to REAL users
              </label>
              <input
                type="checkbox"
                id="is-prod"
                checked={isProd}
                onChange={(e) => setIsProd(e.target.checked)}
              />
              <br />
              <label htmlFor="" className="form-label">
                Connect To Emulator
              </label>
              <input
                type="checkbox"
                id="connect-emulator"
                checked={connectEmulator}
                onChange={(e) => setConnectEmulator(e.target.checked)}
              />
              <br />
              <Button className="mb-3" onClick={onSendListingEmails}>
                Send
              </Button>
            </Form>
          </Col>
          <hr />
        </Row>
        <Row className="justify-content-md-center">
          <Col md="4">
            <h2>Update User Email</h2>
            <Form>
              <label htmlFor="" className="form-label">
                Current Email
              </label>
              <input
                type="text"
                className="form-control"
                id="current-email-input"
                value={currentEmail}
                onChange={(e) => setCurrentEmail(e.target.value)}
                required
              />
              <label htmlFor="" className="form-label">
                New Email
              </label>
              <input
                type="text"
                className="form-control"
                id="new-email-input"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
              {updateEmailResult}
              <br />
              <Button
                className="mb-3"
                onClick={onUpdateUserEmail}
                disabled={disableUpdateEmail}
              >
                Update
              </Button>
            </Form>
          </Col>
          <hr />
        </Row>
        <Row className="justify-content-md-center">
          <Col md="4">
            <h2>Send Push Notification</h2>
            <Form>
              <label htmlFor="" className="form-label">
                Recipient IDs (comma seperated - use EVERYONE@woofyclub.com to
                send to everyone)
              </label>
              <input
                type="text"
                className="form-control"
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                required
              />
              <label htmlFor="" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <label htmlFor="" className="form-label">
                Body
              </label>
              <textarea
                className="form-control"
                rows={4}
                value={body}
                onChange={(e) => setBody(e.target.value)}
                required
              ></textarea>
              {sendPushResult}
              <br />
              <Button
                className="mb-3"
                onClick={onSendPushNotification}
                disabled={disableSendPush}
              >
                Send
              </Button>
            </Form>
          </Col>
          <hr />
        </Row>
      </Container>
    </>
  ) : null;
}
