import { getFunctions, httpsCallable } from "firebase/functions";
import { fbApp } from "libs/firebase";
import { Mixpanel } from "libs/mixpanel";
import React, { Fragment, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { isMobile } from "react-device-detect";
import PostCard from "../common/PostCard";
import MainNavColumn from "../MainNavColumn";

const functionsWest2 = getFunctions(fbApp, "us-west2");
// connectFunctionsEmulator(functions, "localhost", 5001);
// connectFunctionsEmulator(functionsWest2, "localhost", 5001);
const getUserFeeds = httpsCallable(functionsWest2, "getInternalUserFeedsV2");

export const NewsFeed = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [availableListings, setAvailableListings] = useState([]);
  const [sortBy, setSortBy] = useState("recent_activity");
  const settings = {
    sort_by: sortBy,
    feed_limit: 50,
  };

  useEffect(() => {
    setIsLoading(true);
    Mixpanel.track("visit_newsfeed_page");

    getUserFeeds(settings).then((result) => {
      setAvailableListings(result.data);
      setIsLoading(false);
      console.log("no loading");
    });
  }, [sortBy]);

  return (
    // HTML + JSX goes here
    <div>
      <Container className="d-md-block my-2">
        <Row>
          <Col lg="3">
            <MainNavColumn
              profile={props.currentProfile}
              sortBy={sortBy}
              setSortBy={setSortBy}
            />
          </Col>
          <Col lg="6" className="text-start">
            <div className="d-lg-none mb-4 p-4 justify-content-md-center m-0 py-3 border rounded bg-white">
              <h5>Sort by</h5>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="recent-activity-mobile"
                  name="sort_by_mobile"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                  }}
                  value="recent_activity"
                  checked={sortBy === "recent_activity"}
                />
                <label
                  className="form-check-label"
                  htmlFor="recent-activity-mobile"
                >
                  Recent activity
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  id="created-at-mobile"
                  name="sort_by_mobile"
                  onChange={(e) => {
                    setSortBy(e.target.value);
                  }}
                  value="created_at"
                  checked={sortBy === "created_at"}
                />
                <label className="form-check-label" htmlFor="created-at-mobile">
                  Created at
                </label>
              </div>
            </div>
            {!isLoading ? (
              availableListings.map((listingData, index) => (
                <Fragment key={listingData.uid}>
                  <PostCard
                    type="listing"
                    listing={listingData}
                    loggedInProfile={props.currentProfile}
                  />
                  <div className="mb-2" />
                </Fragment>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status" size="lg">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            {isMobile && <div className="mt-3" />}
          </Col>
          <Col lg="3"></Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewsFeed;
